<template>
  <div class="mseries-chart">
    <div id="mseriesChart">
      <!-- <h3>
        {{ header }}
      </h3> -->
      <div class="row">
        <div class="col col-6">
          <label for="viewType"><b>Show:</b></label>
          <select class="form-control" id="viewType" name="viewType" size="1" v-model="viewType"
            @change="setAvgWindow()">
            <option key="count" value="count">Count per week</option>
            <option key="percent" value="percent">
              Percent of sequenced genomes per week
            </option>
          </select>
        </div>
        <div class="col col-6">
          <label for="currentWindow"><b>Average data over:</b></label>
          <select class="form-control" id="currentWindow" name="currentWindow" size="1" v-model="currentWindow"
            @change="setAvgWindow()">
            <option v-for="(value, index) in avgWindows" :key="index" :value="value">
              {{ value }} week{{ value > 1 ? "s" : " (no averaging)" }}
            </option>
          </select>
        </div>
      </div>
      <div class="row m-2" id="mseriesChartSvgDiv">
        <svg id="mseriesChartSvg"></svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VariantsChart",
  props: {
    data: Array,
    weeklyTotals: Array,
    hoveredGroup: String,
    // header: String,
    colorScale: Function,
    svgWidth: Number, // to make it consistent with the histogram
  },
  data: function () {
    return {
      svgHeight: 400, //height of the svg element
      width: 100, //width of the  chart, will change at mount
      height: 100, //height of the  chart, will change at mount
      // for averaging:
      currentWindow: 3,
      avgWindows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      avgMin: 0,
      avgMax: 0,
      viewType: "percent", // 'count' or 'percent'
      mutV2Regex: new RegExp("([a-zA-Z]+)(\\d+)([a-zA-Z]+)", "mi")
    };
  },
  watch: {
    data() {
      this.setAvgWindow();
    },
    hoveredGroup() {
      const self = this;
      // unlighlight current chart
      self.svg.selectAll("path").attr("fill-opacity", 0.1);
      //highlight new one
      //hoveredColumn.data.pdbPos + '-' + hoveredSubgroupName
      if (self.hoveredGroup != null)
        self.svg
          .selectAll("#" + self.stringToId(self.hoveredGroup))
          .attr("fill-opacity", 1);
    },
    colorScale() {
      this.updateChart();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    log: function (msg) {
      const t = Date.now() - this.START;
      // eslint-disable-next-line no-console
      console.log(`MSChart@ ${t}ms: ${msg}`);
    },
    init() {
      this.svgHeight = 400;
      const margin = { top: 10, right: 30, bottom: 20, left: 50 };

      // update the dimensions and margins of the chart
      this.width = this.svgWidth - margin.left - margin.right;
      this.height = this.svgHeight - margin.top - margin.bottom;

      // append the svg object to the body of the page
      let svgEl = window.d3
        .select("#mseriesChartSvg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      this.svg = svgEl
        .append("g")
        .attr("id", "mseriesChartCanvas")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      this.setAvgWindow();
    },
    mutationToV1(mutIn) {
      // convert mutation string from A123B to 123:A>B
      const groups = mutIn.match(this.mutV2Regex);
      if (!groups || groups.length === 0)
        return mutIn;
      else return `${groups[2]}:${groups[1]}>${groups[3]}`;
    },
    stringToId(s) {
      // GrowthCHart+codonAA
      return (
        "gch" +
        s
          .trim()
          .replaceAll(">", "__")
          .replaceAll("*", "__star__")
          .replaceAll("-r", "-")
          .replaceAll(":", "-")
      );
    },
    colorForAA(aa) {
      // given mutation in format 123:A>B return color based on AA grouping
      if (!aa || aa.length === 0) return "#FFFFFF";

      const p = this.mutationToV1(aa).split(":");
      if (p.length < 2) return "#FFFFFF";
      const idString =
        "r" + p[1].trim().replaceAll(">", "__").replaceAll("*", "__star__");
      return this.colorScale(idString);
    },
    updateChart() {
      const d3 = window.d3;
      if (!d3) {
        this.log("D3 not found");
        return;
      }
      if (!this.data || this.data.length === 0) {
        this.log("Data is not provided");
        return;
      }
      // clear canvas
      d3.select("#mseriesChartCanvas").selectAll("*").remove();

      const self = this;

      let dates = [];
      self.data.columns.forEach((c) => {
        if (c != "Mutations" && c != "Total") dates.push(new Date(c));
      });

      // Add X axis --> it is a date format
      const x = d3.scaleTime().domain(d3.extent(dates)).range([0, self.width]);
      x.ticks(20);

      self.svg
        .append("g")
        .attr("transform", "translate(0," + this.height + ")")
        .call(d3.axisBottom(x).ticks(5));

      let min = self.avgMin;
      let max = self.avgMax;
      // Add Y axis
      const y = d3.scaleLinear().domain([min, max]).range([self.height, 0]);

      self.svg.append("g").call(d3.axisLeft(y));

      // Draw the area
      self.svg
        .selectAll(".area")
        .data(self.data)
        .enter()
        .append("path")
        .attr("id", (r) => self.stringToId(r.Mutations))
        .attr("fill", (r) => self.colorForAA(r.Mutations))
        .attr("fill-opacity", 0.1)
        .attr("stroke", "none")
        // .attr("stroke", (r) => self.colorForAA(r.Mutations))
        // .attr("stroke-width", 4)
        .attr("d", function (r) {
          //d is the single row
          return d3
            .area()
            .x(function (d) {
              return x(new Date(d[0]));
            })
            .y0(y(0))
            .y1(
              // .y(
              function (d, i) {
                return y(r.avg[i]);
              }
            )(Object.entries(r).filter((a) => Date.parse(a[0])));
        });

      // Draw the line
      self.svg
        .selectAll(".line")
        .data(self.data)
        .enter()
        .append("path")
        .attr("id", (r) => self.stringToId(r.Mutations))
        .attr("fill", "none")

        .attr("stroke", (r) => self.colorForAA(r.Mutations))
        .attr("stroke-width", 2)
        .attr("d", function (r) {
          //d is the single row
          return d3
            .area()
            .x(function (d) {
              return x(new Date(d[0]));
            })
            .y(
              // .y(
              function (d, i) {
                return y(r.avg[i]);
              }
            )(Object.entries(r).filter((a) => Date.parse(a[0])));
        });


      // for the tooltip


      const tooltip = d3
        .select("#mseriesChartSvgDiv")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px");

      // rect to catch mouse events
      const oneWeek = x(dates[1]) - x(dates[0]);
      const yPos = y(0) - self.height; // y coordinate to rect

      self.svg
        .selectAll("rect")
        .data(dates)
        .enter()
        .append("rect")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .style("pointer-events", "all")
        .attr("width", oneWeek)
        .attr("height", self.height)
        .attr("x", (d) => x(d))
        .attr("y", yPos)
        .attr("data-idx", (d, i) => i)
        .on("mouseover", function () {
          tooltip.style("opacity", 1);
          d3.select(this).style("stroke", "#BBB");
        })
        .on("mousemove", function () {
          // c
          const idx = d3.select(this).attr("data-idx");
          const date = dates[idx];

          const week = `${date.getFullYear()}-${date.getMonth() + 1
            }-${date.getDate()}`;

          let dataString = "";
          self.data.forEach((r) => {
            const color = self.colorForAA(r.Mutations);
            dataString += `<br><span style="color:${color}"><b>${r.Mutations}</b>: ${r.raw[idx].toPrecision()} | ${r.avg[idx].toPrecision()}</span>`;
          });

          tooltip
            .html(
              `<b>Week of ${week}</b><br>
              <span class="text-muted">Mutation: raw count | value</span>
              ${dataString}
            `
            )
            .style("left", d3.pointer(event, this)[0] - 190 + "px")
            .style("top", d3.pointer(event, this)[1] + 140 + "px");
        }).on("mouseleave", function () {
          d3.select(this).style("stroke", "none");
        });

      d3.select("#mseriesChartCanvas").on("mouseleave", function () {
        tooltip.transition().duration(1500).style("opacity", 0);
      });
    },

    getAveragedValue(d, i, row) {
      return row.avg[i];
    },

    setAvgWindow() {
      if (!this.data || this.data.length === 0) {
        this.log(`No data provided`);
        return;
      }

      const self = this;
      self.avgMin = 0;
      self.avgMax = 0;
      self.data.forEach((row) => {
        let arr; // araay to use for averaging, depends on the sselected view type
        if (self.viewType === "percent" && self.weeklyTotals)
          arr = row.raw.map((d, i) => {
            let t = self.weeklyTotals[i + 1]; // first element is a placeholder, skip it
            if (!t) {
              self.log(`Unable to calculate total for ${d} [@${i}]`);
              return d;
            }
            return (d * 100) / t;
          });
        else arr = row.raw;
        row.avg = self.averageArrayOverWindow(arr, self.currentWindow);
      });
      this.updateChart();
    },
    averageArrayOverWindow(arr, win) {
      if (win === 1) {
        // no averaging needed, just update min/max
        this.avgMin = Math.min(this.avgMin, ...arr);
        this.avgMax = Math.max(this.avgMax, ...arr);
        return arr;
      }
      // copy from averageWorker.js with some changes
      let l = arr.length;
      let result = new Array(l);
      result.fill(0);
      // number of residues before position
      let before = Math.floor(win / 2);
      let after = Math.ceil(win / 2) - 1;

      //first and last mutations in the range
      let start = 0,
        end = 0;

      let rollingSum = 0;
      let rollingAvg = 0;
      ////initial sum
      for (let p = 0; p <= after; p++) {
        rollingSum += arr[p];
      }

      for (let p = 0; p < l; p++) {
        start = Math.max(p - before, 0);
        end = Math.min(p + after, l - 1);
        rollingSum += arr[end]; //last;
        rollingSum -= arr[start]; //first;

        rollingAvg = start === end ? arr[end] : rollingSum / (end - start);
        result[p] = +rollingAvg.toPrecision(2);
      }

      this.avgMin = Math.min(this.avgMin, ...result);
      this.avgMax = Math.max(this.avgMax, ...result);

      return result;
    },
  },
};
</script>